import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import * as Yup from 'yup';
import AdminContainer from '../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../contexts/breadcrumbs-provider';
import Loadable from '../../../data/loadable';
import Heading from '../../../layout/admin/heading';
import { getCenterAdminToken } from '../../../../utils/auth';
import { orderTeamsByLanes } from '../../../../utils/helpers';
import { getApiRequest, postApiRequest } from '../../../../utils/request';
import SEO from '../../../layout/seo';
import Table from '../../../data/table';
import { useNotifications } from '../../../../contexts/notifications-provider';
import Form from '../../../form/form';


function ManageLanes({ product, teams, reload }) {
  const { addNotification } = useNotifications();

  if (!product || !teams || !teams.length) {
    return null;
  }


  const { meta } = product;
  const { lanes } = meta || {};

  const initialValues = {};
  const validationSchema = {};
  const fields = [];

  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    const name = `team_${team.id}`;
    fields.push({
      name,
      label: `Lane for ${team.name}`,
      type: 'number',
    });
    initialValues[name] = lanes && lanes[name] ? lanes[name] : '';
    validationSchema[name] =  Yup.string().required('You must provide a lane number for each team.');
  }

  const form = {
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values) => {
      // console.log(values, 'values in submit');
      try {
        await postApiRequest(`/center-admin/product/${product.id}/lanes`, { password: getCenterAdminToken(), lanes: values });
        addNotification({
          type: 'success',
          body: 'The team lane assignments were updated.',
          timeout: 3000,
        });
        await reload();
      } catch (err) {
        if (err.response && err.response.status === 401) {
          return;
        }
        addNotification({
          type: 'error',
          body: 'There was an error saving the lanes.',
        });
      }
    },
    fields,
    submit: {
      label: 'Save Lanes',
    },
  };


  return (
    <div className="px-0 md:px-8 my-8 max-w-max">
      <h2 className="mb-2 font-semibold text-lg">Manage Lanes</h2>
      <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
        <Form {...form} />
      </div>
    </div>
  );
}


function TotalScoreboard({ scoreboard }) {
  if (!scoreboard) {
    return (
      <div className="px-0 md:px-8 my-8 max-w-max">
        <h2 className="mb-2 font-semibold text-lg">Scoreboard</h2>
        <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
          <p className="mb-2">There are no scores yet.</p>
        </div>
      </div>
    );
  }

  // Translate to a table
  const columns = [
    {
      id: 'name',
      key: 'name',
      label: `Team`,
    },
    {
      id: 'total_points',
      key: 'total_points',
      label: `Points`,
      isBold: true,
      isRight: true,
    },
  ];
  const rows = [];
  // each row is position, name, points etc, for the spot in the scores array
  for (let i = 0; i < scoreboard.length; i++) {
    const info = scoreboard[i];
    const row = {
      id: info.team_id,
      name: info.name,
      total_points: info.total_points,
    };
    rows.push(row);
  }

  // console.log(localScores, 'local scores...');

  return (
    <div className="px-0 md:px-8 my-8 max-w-max">
      <h2 className="mb-2 font-semibold text-lg">Scoreboard</h2>
      <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
        <Table columns={columns} rows={rows} />
      </div>
    </div>
  );
}

const CenterAdminScoreBoard = ({ productId, week }) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [teams, setTeams] = useState(null);
  const [scoreboard, setScoreboard] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { addNotification } = useNotifications();

  const loadProductAndTeams = async () => {
    setLoading(true);
    try {
      const result = await getApiRequest(`/center-admin/product/${productId}?password=${getCenterAdminToken()}`);
      setProduct(result);
      const teamResult = await getApiRequest(`/center-admin/product/${productId}/teams?password=${getCenterAdminToken()}`);
      const orderedTeams = orderTeamsByLanes(result, teamResult.teams || []);
      setTeams(orderedTeams);
      const scoreboardResult = await getApiRequest(`/center-admin/scoring/scoreboard/${productId}?password=${getCenterAdminToken()}`);
      setScoreboard(scoreboardResult.scores);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        return;
      }
      addNotification({
        type: 'error',
        body: 'There was an error loading the league for the scoring app.',
      });
      navigate('/app/center-admin/dashboard');
    }
    setLoading(false);
  };


  useEffect(() => {
    setLoading(true);
    setBreadcrumbs([
      {
        href: '/app/center-admin/dashboard',
        label: 'Dashboard',
      },
      {
        href: `/app/center-admin/scoring/scoreboard?product_id=${productId}`,
        label: 'Scoreboard',
      },
    ]);
    
    loadProductAndTeams();
  }, []);


  const title = loading ? '' : `${product?.name} Scoreboard`;

  const weekLinks = [];
  const numWeeks = product && product.num_weeks ? product.num_weeks : product && product.league && product.league.num_weeks ? product.league.num_weeks : 8;
  // First week is practice
  const limit = numWeeks + 1;
  for (let i = 1; i <= limit; i++) {
    weekLinks.push(`/app/center-admin/scoring/app?product_id=${productId}&week=${i}`);
  }

  return (
    <AdminContainer centerAdmin>
      <SEO title="Scoreboard" />
      <Heading title={title} />
      <Loadable loading={loading}>
        <div className="grid grid-cols-3 space-x-2">
          <TotalScoreboard scoreboard={scoreboard} />
          <ManageLanes reload={loadProductAndTeams} product={product} teams={teams} />
          <div className="px-0 md:px-8 my-8 max-w-max">
            <h2 className="mb-2 font-semibold text-lg">Manage Scores</h2>
            <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-200">
              {weekLinks.map((href, i) => {
                return (
                  <div className="my-4 mx-4" key={href}>
                    <Link
                      to={href}
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Week {i+1} Scoring{i === 0 ? ' (Practice)' : ''}
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Loadable>
    </AdminContainer>
  );
}

export default CenterAdminScoreBoard;
