import React, { useEffect, useState } from 'react';
import CenterAdminScoreBoard from '../../../../components/pages/private/center-admin/scoreboard';
import PrivateRoute from '../../../../components/private-route';
import queryString from 'query-string';
import { isBrowser } from '../../../../utils/helpers';

export default function ScoringApp() {
  const [id, setId] = useState(null);
  const [init, setInit] = useState(false);
  useEffect(() => {
    const params = queryString.parse(isBrowser ? window.location.search : '');
    if (params && params.product_id) {
      setId(params.product_id);
    }
    setInit(true);
  }, []);
  return init ? <PrivateRoute centerAdmin component={CenterAdminScoreBoard} productId={id} /> : null;
}
